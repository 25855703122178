// Forms
.form-label {
  color: $label-color;
  font-weight: $label-font-weight;
}

.form-check-emphasized {
  border: $border-grey;
  display: inline-block;
  padding: $spacer * .5; // check spacing variables
  border-radius: $border-radius;// check border variables
}

.form-check-emphasized .form-check-input {
  margin-left: 0;
  float: unset;
}

.form-check-emphasized.selected {
  border: $border-green;
  background-color: $sn-clr-light-green;
  color: $sn-clr-green;
}

.form-check-emphasized label {
  margin-left: $spacer * .5; // check spacing variables
}
