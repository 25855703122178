// San'yas search input component
.input-search::placeholder {
  color: $sn-clr-deep-grey;
}

.input-search {
    width: 260px;
    border: 1px solid #555;
    display: block;
    padding: 9px 4px 9px 40px;
    background: $sn-clr-dark-grey url('data:image/svg+xml,<svg width="14" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.53 10.134A4.813 4.813 0 1 1 3.845 1.24a4.813 4.813 0 0 1 3.683 8.894zm2.744-1.084a5.688 5.688 0 1 0-1.223 1.224c.026.036.055.07.086.1l3.37 3.37a.875.875 0 0 0 1.236-1.239l-3.368-3.368a.876.876 0 0 0-.1-.087z" fill="%23fff"/></svg>') no-repeat 13px center;
    color: $sn-clr-light-grey;
}

/* clears the ‘X’ from Internet Explorer */
input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
/* clears the ‘X’ from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

